import {
  RiLinkedinFill,
  RiGithubFill,
  RiFileDownloadLine,
} from "react-icons/ri";
import javascript from "./assets/img/javascript.svg";
import reactLogo from "./assets/img/react.svg";
import nodejs from "./assets/img/nodejs.svg";
import Typewriter from "typewriter-effect";
import "./App.css";
import { useIntroAnim } from "./hooks/useIntroAnim";

function App() {
  const stack = [javascript, reactLogo, nodejs];
  const date = new Date().getFullYear();

  /* Hooks */
  const { loading, none } = useIntroAnim();

  return (
    <div className="App">
      {!none && (
        <div className={`App-loading ${loading ? "visible" : "hidden"}`}>
          <Typewriter
            onInit={(typewriter) => {
              typewriter

                .typeString("Hi world !")

                .pauseFor(1000)
                .deleteAll()
                .start();
            }}
          />
        </div>
      )}

      <div>
        <h1 className="App-title">Ismaël Mmadi</h1>
        <h2 className="App-subtitle">JS web developer</h2>
        <div className="App-stack">
          {stack.map((tech, id) => (
            <img
              key={id}
              src={tech}
              alt="tech-logo"
              className="App-stack-logo"
            />
          ))}
        </div>
      </div>
      <span className="App-soon">Coming soon</span>
      <div className="App-social">
        <a href={process.env.REACT_APP_CV}>
          <RiFileDownloadLine color="#fff" size={35} />
        </a>
        <a href="https://github.com/ismael2m">
          <RiGithubFill color="#fff" size={35} />
        </a>
        <a href="https://www.linkedin.com/in/ismael-mmadi-dev/">
          <RiLinkedinFill color="#fff" size={35} />
        </a>
      </div>
      <footer>© {date} — Design by Me</footer>
    </div>
  );
}

export default App;
