import { useState, useEffect } from "react";

export const useIntroAnim = () => {
  const [loading, setLoading] = useState(true);
  const [none, setDisplay] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading((loading) => !loading);
      setTimeout(() => {
        return setDisplay((none) => !none);
      }, 800);
    }, 4000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return { loading, none };
};
